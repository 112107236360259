var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"99%","icon":"mdi-cellphone-dock","color":"primary","title":"Variaciones","text":"Resumen de Variaciones"}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.variations,"page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Filtrar: "+_vm._s(_vm.search)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":"search","placeholder":"Escribe el texto","single-line":"","outlined":"","clearable":""},on:{"input":_vm.handleSearchUpdate},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CountrySelect',{model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleProductsCrossover}},[_vm._v(" Sincronizar ")]),(_vm.productsCrossoverSku.length)?_c('div',{staticClass:"controls-container"},[_c('div',[(_vm.currentProductIndex > 0)?_c('v-btn',{on:{"click":function($event){return _vm.handleCurrentProductChange(_vm.currentProductIndex - 1)}}},[_vm._v(" Anterior ")]):_vm._e(),(
                        _vm.currentProductIndex < _vm.productsCrossoverSku.length - 1
                      )?_c('v-btn',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleCurrentProductChange(_vm.currentProductIndex + 1)}}},[_vm._v(" Siguiente ")]):_vm._e()],1),_c('div',[_vm._v(" "+_vm._s(_vm.currentProductIndex + 1)+" / "+_vm._s(_vm.productsCrossoverSku.length)+" ")])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"label":"Archivados"},on:{"change":function($event){return _vm.initialize(_vm.page)}},model:{value:(_vm.filterByArchived),callback:function ($$v) {_vm.filterByArchived=$$v},expression:"filterByArchived"}})],1)],1),(_vm.variationsSelected.length)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.batchUpdate(true)}}},[_vm._v(" On ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.batchUpdate(false)}}},[_vm._v(" OFF ")])],1)],1):_vm._e()],1)]},proxy:true},{key:"item.checkbox",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_c('v-checkbox',{attrs:{"value":_vm.getCheckboxValue(item)},on:{"change":function (val) { return _vm.handleCheckboxChange(val, item); }}})],1)]}},{key:"item.switch",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_c('v-switch',{attrs:{"input-value":item.stock > 0,"loading":_vm.loading.includes(item._id)},on:{"change":function (val) { return _vm.handleSwitchChange(val, item); }}})],1)]}},{key:"item.source",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.product ? item.product.source : '')+" ")])]}},{key:"item.sku",fn:function(ref){
                      var item = ref.item;
return [(item.url || (item.product && item.product.url))?_c('a',{attrs:{"href":item.url || item.product.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.sku)+" ")]):_c('span',[_vm._v(_vm._s(item.sku))])]}},{key:"item.price",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]}},{key:"item.stock",fn:function(ref){
                      var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":"","persistent":""},on:{"save":function($event){return _vm.handleStockSave(item)},"open":function($event){_vm.currentStock = item.stock}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Modificar Stock ")]),_c('v-text-field',{ref:"stockTextEdit",attrs:{"label":"Edit","type":"number","min":"0","rules":_vm.stockRules,"single-line":"","autofocus":""},model:{value:(_vm.currentStock),callback:function ($$v) {_vm.currentStock=$$v},expression:"currentStock"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item.stock))])])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"secondary","icon":""},on:{"click":function($event){return _vm.handleSyncVariation(item)}}},[_c('v-icon',{class:_vm.loading.includes(item._id) ? 'loading' : ''},[_vm._v("mdi-sync")])],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(" Aún no cuentas con variaciones ")])]},proxy:true}])}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.variations.length ? _vm.variations.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.variations.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":_vm.$store.state.maxPaginationButtons},on:{"input":function($event){return _vm.initialize(_vm.page)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }